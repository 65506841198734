<template>
  <Layout>
    <div id="receipt">
      <div class="grid-x grid-padding-x">
        <div class="cell">
          <!-- <h3>Summary</h3> -->
            <!-- <div id="summary"> -->
              <!-- <template v-if="info.travel"> -->
                <!-- <p>Review before you submit.</p> -->

                <div class="header cell">
                  <div class="top cell" align="center">
                    <h2>Thank you</h2>
                    <p class="h3">Your request has been submitted.<br />You will be notified when your request has been processed.</p>
                    <!-- <figure>
                      <img src="../assets/life-bus.jpg" alt="">
                    </figure> -->
                  </div>
                  <div class="bottom cell" align="center">
                    <p class="h3">Do you know someone in Caledon that could use CCS Transportation? Let them know!</p>
                    <a href="mailto:[Replace with your contact's email address]?subject=Do%20You%20Need%20a%20Ride%3F&cc=bookings@ccs4u.org&body=%0A%0ACheck%20out%20affordable%20and%20accessible%20transportation%20options%20available%20to%20you%20through%20Caledon%20Community%20Services%20for%20ages%208%2B!%0A%0AFor%20more%20information%20or%20to%20book%20a%20ride%2C%20visit%20https://ccs4u.org%2Ftransportation%20or%20call%20905-584-2300%20%7C%201-833-552-2748%20ext.217%2C%20218%2C%20or%20264." target="_blank" rel="noopener noreferrer">
                      <button type="button" class="primary btn button filled no-margin">Spread the Word</button>
                    </a>
                  </div>
                </div>

                <div class="cell">

                  <div v-if="info.travel === true" class="grid-x grid-padding-x personal">

                    <div class="medium-4 cell">
                      <p class="h3">RIDE FOR</p>
                    </div>

                    <div class="medium-8 cell">
                      <p class="h3">
                        {{ info.first }} {{ info.last }}<br />
                        <span v-if="info.email">{{ info.email }}<br /></span>
                        <span v-if="info.phone">{{ info.phone }}<br /></span>
                        Account Type: {{ info.type }}<br />
                        <span v-if="info.account">Account Number: {{ info.account }}</span>
                      </p>
                    </div>

                  </div>

                  <div v-if="info.travel === false" class="grid-x grid-padding-x personal">

                    <div class="medium-4 cell">
                      <p class="h3">ACCOUNT HOLDER</p>
                    </div>

                    <div class="medium-8 cell">
                      <p class="h3">
                        {{ info.first }} {{ info.last }}<br />
                        <span v-if="info.email">{{ info.email }}<br /></span>
                        <span v-if="info.phone">{{ info.phone }}<br /></span>
                        Account Type: {{ info.type }}<br />
                        <span v-if="info.account">Account Number: {{ info.account }}</span>
                      </p>
                    </div>

                  </div>

                  <hr v-if="info.travel === true" />

                  <div v-if="ride.thirdParty.isThirdParty === true" class="grid-x grid-padding-x agent">

                    <div class="medium-4 cell">
                      <p class="h3">BOOKED BY</p>
                    </div>

                    <div class="medium-8 cell">
                      <p class="h3">
                        {{ ride.thirdParty.firstName }} {{ ride.thirdParty.lastName }}<br />
                        <span v-if="ride.thirdParty.email">{{ ride.thirdParty.email }}<br /></span>
                        <span v-if="ride.thirdParty.phone">{{ ride.thirdParty.phone }}<br /></span>
                      </p>
                    </div>

                  </div>

                  <hr v-if="ride.thirdParty.isThirdParty === true" />

                  <div v-if="info.travel === true" class="grid-x grid-padding-x cusomization">

                    <div class="medium-4 cell">
                      <p class="h3">RIDE CUSTOMIZATION</p>
                    </div>

                    <div class="medium-8 cell">
                      <p class="h3">
                        Companion x {{ ride.companion }}<br />
                        <span v-if="ride.escort === true">Escort x 1<br /></span>
                        <span v-if="ride.escort === false">Escort x 0<br /></span>
                        Mobility Aid: {{ ride.mobilityAid }}
                      </p>
                    </div>

                  </div>

                  <hr v-if="info.travel === true" />

                  <div v-if="info.travel === true" class="grid-x grid-padding-x information">

                    <div class="medium-4 cell">
                      <p class="h3">RIDE INFORMATION</p>
                    </div>

                    <div class="medium-8 cell">

                      <div class="cell">
                        <p class="h3">
                          {{ ride.purpose }}<br />
                          {{ ride.date }}
                        </p>
                      </div>
                      <div class="grid-x grid-padding-x information">

                        <div class="medium-1 cell marker">
                          <p></p>
                        </div>
                        <div class="medium-11 cell section">
                          <p class="h3">
                            {{ ride.startAddress.number }} {{ ride.startAddress.street }}, {{ ride.startAddress.city }}, {{ ride.startAddress.postalCode }}<br />
                          </p>
                        </div>
                      </div>
                      <div class="grid-x grid-padding-x information">
                        <div class="medium-1 cell marker dest">
                          <p></p>
                        </div>
                        <div class="medium-11 cell section">
                          <p class="h3">
                            {{ ride.destinationAddress.number }} {{ ride.destinationAddress.street }}, {{ ride.destinationAddress.city }}, {{ ride.destinationAddress.postalCode }}<br />
                            {{ ride.time }}
                          </p>
                        </div>

                      </div>

                    </div>

                  </div>

                  <hr />

                  <div v-if="ride.instructions" class="grid-x grid-padding-x special">

                    <div class="medium-4 cell">
                      <p class="h3">SPECIAL INSTRUCTIONS</p>
                    </div>

                    <div class="medium-8 cell">

                      <div class="grid-x grid-padding-x information">

                        <div class="cell">
                          <p class="h3">
                            {{ ride.instructions }}
                          </p>
                        </div>

                      </div>

                    </div>

                  </div>

                  <hr v-if="ride.instructions" />

                  <div v-if="ride.returnTrip === true" class="grid-x grid-padding-x return">

                    <div class="medium-4 cell">
                      <p class="h3">RETURN RIDE<br />INFORMATION</p>
                    </div>

                    <div class="medium-8 cell">

                      <div class="grid-x grid-padding-x information">

                        <div class="medium-1 cell marker dest return">
                          <p></p>
                        </div>
                        <div class="medium-11 cell section">
                          <p class="h3" v-if="ride.pickupAddress === 'same'">
                            {{ ride.destinationAddress.number }} {{ ride.destinationAddress.street }}, {{ ride.destinationAddress.city }}, {{ ride.destinationAddress.postalCode }}<br />
                            {{ ride.pickupTime }}
                          </p>
                          <p class="h3" v-else>
                            {{ ride.pickupAddress.number }} {{ ride.pickupAddress.street }}, {{ ride.destinationAddress.city }}, {{ ride.destinationAddress.postalCode }}<br />
                            {{ ride.pickupTime }}
                          </p>
                        </div>
                      </div>
                      <div class="grid-x grid-padding-x information">
                        <div class="medium-1 cell marker orig return">
                          <p></p>
                        </div>
                        <div class="medium-11 cell section">
                          <p class="h3" v-if="ride.returnAddress === 'same'">
                            {{ ride.startAddress.number }} {{ ride.startAddress.street }}, {{ ride.startAddress.city }}, {{ ride.startAddress.postalCode }}
                          </p>
                          <p class="h3" v-else>
                            {{ ride.returnAddress.number }} {{ ride.returnAddress.street }}, {{ ride.startAddress.city }}, {{ ride.startAddress.postalCode }}
                          </p>
                        </div>

                      </div>

                    </div>

                  </div>

                  <hr v-if="ride.returnTrip === true" />

                  <div class="grid-x grid-padding-x transaction">

                    <div class="medium-4 cell">
                      <p class="h3">PAYMENT</p>
                    </div>

                    <div class="medium-8 cell">

                      <div class="grid-x grid-padding-x information">

                        <div v-if="info.type !== 'transHelp'" class="cell">
                          <p class="h3">
                            <!-- Transaction Date: {{ receipt.transaction_date_time }}<br /> -->
                            <!-- Approval: {{ receipt.approval_code }}<br /> -->
                            <!-- Transaction ID: {{ receipt.transaction_no }}<br /> -->
                            Reference Number: {{ receipt.reference_no }}<br />
                            <!-- Response Code: {{  receipt.response_code }}, ISO Code: {{ receipt.iso_response_code }}<br /> -->
                            <span v-if="receipt.card_type === 'V'">Card Type: Visa</span>
                            <span v-if="receipt.card_type === 'M'">Card Type: MasterCard</span>
                            <span v-else>Card Type: Card</span>
                          </p>
                          <p v-if="ride.returnTrip === true" class="h3">
                            Charged Riders x {{ ride.companion }}<br />
                            <span v-if="ride.escort === true">Escort x 1<br /><br /></span>
                            <span v-if="ride.escort === false">Escort x 0</span>
                          </p>
                          <hr />
                          <p v-if="ride.returnTrip === true" class="h3">
                            <strong>Total Amount: ${{ ride.price }}</strong>
                          </p>
                          <p v-if="ride.returnTrip === false" class="h3">
                            <strong>Total Amount: ${{ receipt.amount }}</strong>
                          </p>
                        </div>
                        <div v-if="info.type === 'transHelp'" class="cell">
                          <p class="h3">
                            <em>Please contact Region of Peel to arrange ride payments.</em>
                          </p>
                        </div>

                      </div>

                    </div>

                  </div>

                  <hr v-if="info.travel === true" />

                  <div class="message" align="center">
                    <p v-if="info.travel === true" class="h3 red">If you wish to cancel or change your ride booking, please <a href="#">email us</a> or give us a call <a href="#">(905)&nbsp;584-2300</a> or <a href="#">1-833-552-2748</a> Ext.&nbsp;217,&nbsp;218,&nbsp;or&nbsp;264.<br />
                    Last day to cancel without incurring any fees is the day before your ride by 2:00pm.</p>
                    <p v-if="info.travel === false" class="h3 red">If you wish to cancel or change your ride booking, please <a href="#">email us</a> or give us a call <a href="#">(905)&nbsp;584-2300</a> or <a href="#">1-833-552-2748</a> Ext.&nbsp;217,&nbsp;218,&nbsp;or&nbsp;264.<br />
                    Last day to cancel without incurring any fees is the day before your ride by 2:00pm.</p>
                  </div>

                  <hr />

                  <div class="grid-x buttons">

                    <div class="small-5 cell">

                      <div class="grid-x">

                        <div class="small-6 cell">
                          <!-- <button type="button" class="primary btn button float-right"> Pay from my account </button> -->
                          <button type="button" class="primary btn button hollow no-margin float-left" onclick="window.print()">Print</button>
                        </div>

                        <div class="small-6 cell">
                          <button type="button" class="primary btn button hollow no-margin float-left" @click="sendEmail(false)">Email</button>
                        </div>

                      </div>

                    </div>

                    <div class="small-7 cell">
                      <button type="button" class="primary btn button hollow no-margin float-right" @click="$router.push('/')">HOME</button>
                    </div>

                  </div>

                </div>

              <!-- </template> -->

            <!-- </div> summary -->

            <!-- <p>no ticket...</p> #} -->

        </div>
      </div>
      <!-- <button @click="$emit('show')">modal</button> -->
    </div>
  </Layout>
</template>

<script>
// import this.axios from 'this.axios'
import { useInfo, useRide, useTxn } from '../store'
import Layout from '@/components/Layout.vue'

export default {
  components: { Layout },
  created () {
    if (!this.info.account) {
      this.$router.replace('/')
      return
    } else if (this.info.travel && !this.ride.startAddress.city) {
      this.$router.replace('/trip-planner')
      return
    }

    this.sendEmail()
  },
  mounted () {
    document.getElementsByTagName('body')[0].classList.remove('checkoutHtmlStyleFromiFrame')
  },
  beforeUnmount () {
    this.infoState.clear()
    this.rideState.clear()
    this.txnState.clear()
  },
  data () {
    return {
      infoState: useInfo(),
      rideState: useRide(),
      txnState: useTxn(),
      showError: false,
      errorMessage: ''
      // checkout: new window.monerisCheckout() // eslint-disable-line new-cap
    }
  },
  computed: {
    info () {
      return this.infoState.info
    },
    ride () {
      return this.rideState.ride
    },
    receipt () {
      return this.txnState.receipt
    }
  },
  methods: {
    sendEmail (admin = true, customer = true) {
      this.axios.post(`/booking/confirm?admin=${admin}&customer=${customer}`, {
        info: this.info,
        ride: this.info.travel ? this.ride : {},
        txn: this.receipt.order_no ? this.receipt : {}
      }).then(res => {
        console.info(' ::: receipt email', res)
      })
    }
  }
}
</script>
