<template>
  <Layout>
    <div id="summary">
      <div id="monerisCheckout"></div>
      <div class="grid-x grid-padding-x">
        <div class="cell">
          <h2 v-if="info.travel">Summary</h2>
          <h2 v-else>Review your Payment</h2>
            <div>
              <template v-if="info.travel">
                <p class="h3">Review before you submit.</p>

                <div class="cell">

                  <div class="grid-x grid-padding-x personal">

                    <div class="medium-4 cell">
                      <p class="h3">RIDE FOR</p>
                    </div>

                    <div class="medium-8 cell">
                      <p class="h3">
                        {{ info.first }} {{ info.last }}<br />
                        <span v-if="info.email">{{ info.email }}<br /></span>
                        <span v-if="info.phone">{{ info.phone }}<br /></span>
                        {{ info.type }}<span v-if="info.account">: {{ info.account }}</span>
                      </p>
                    </div>

                  </div>

                  <hr />

                  <div v-if="ride.thirdParty.isThirdParty === true" class="grid-x grid-padding-x personal">

                    <div class="medium-4 cell">
                      <p class="h3">BOOKED BY</p>
                    </div>

                    <div class="medium-8 cell">
                      <p class="h3">
                        {{ ride.thirdParty.firstName }} {{ ride.thirdParty.lastName }}<br />
                        <span v-if="ride.thirdParty.email">{{ ride.thirdParty.email }}<br /></span>
                        <span v-if="ride.thirdParty.phone">{{ ride.thirdParty.phone }}<br /></span>
                        <!-- {{ ride.type }}<span v-if="ride.account">: {{ ride.account }}</span> -->
                      </p>
                    </div>

                  </div>

                  <hr v-if="ride.thirdParty.isThirdParty === true" />

                  <div class="grid-x grid-padding-x cusomization">

                    <div class="medium-4 cell">
                      <p class="h3">RIDE CUSTOMIZATION</p>
                    </div>

                    <div class="medium-8 cell">
                      <p class="h3">
                        <span v-if="ride.companion != 0">Companion x {{ ride.companion }}<br /></span>
                        <span v-if="ride.escort === true">Escort x 1<br /></span>
                        Mobility Aid: {{ ride.mobilityAid }}<br />
                      </p>
                    </div>

                  </div>

                  <hr />

                  <div class="grid-x grid-padding-x information">

                    <div class="medium-4 cell">
                      <p class="h3">RIDE INFORMATION</p>
                    </div>

                    <div class="medium-8 cell">

                      <div class="cell">
                        <p class="h3">
                          {{ ride.purpose }}<br />
                          {{ ride.date }}
                        </p>
                      </div>

                      <div class="grid-x grid-padding-x information">

                        <div class="medium-1 cell marker">
                          <p></p>
                        </div>
                        <div class="medium-11 cell section">
                          <p class="h3">
                            {{ ride.startAddress.number }} {{ ride.startAddress.street }}, {{ ride.startAddress.city }}, {{ ride.startAddress.postalCode }}
                          </p>
                        </div>
                      </div>

                      <div class="grid-x grid-padding-x information">
                        <div class="medium-1 cell marker dest">
                          <p></p>
                        </div>
                        <div class="medium-11 cell section">
                          <p class="h3">
                            {{ ride.destinationAddress.number }} {{ ride.destinationAddress.street }}, {{ ride.destinationAddress.city }}, {{ ride.destinationAddress.postalCode }}<br />
                            {{ ride.time }}
                          </p>
                        </div>

                      </div>

                    </div>

                  </div>

                  <hr />

                  <div class="grid-x grid-padding-x return">

                    <div class="medium-4 cell">
                      <p class="h3">RETURN RIDE INFORMATION</p>
                    </div>

                    <div class="medium-8 cell">

                      <div class="grid-x grid-padding-x information">

                        <div class="medium-1 cell marker dest return">
                          <p></p>
                        </div>
                        <div class="medium-11 cell section">
                          <p class="h3" v-if="ride.pickupAddress === 'same'">
                            {{ ride.destinationAddress.number }} {{ ride.destinationAddress.street }}, {{ ride.destinationAddress.city }}, {{ ride.destinationAddress.postalCode }}<br />
                            {{ ride.pickupTime }}
                          </p>
                          <p class="h3" v-else>
                            {{ ride.pickupAddress.number }} {{ ride.pickupAddress.street }}, {{ ride.destinationAddress.city }} {{ ride.destinationAddress.postalCode }}<br />
                            {{ ride.pickupTime }}
                          </p>
                        </div>
                      </div>
                      <div class="grid-x grid-padding-x information">
                        <div class="medium-1 cell marker orig return">
                          <p></p>
                        </div>
                        <div class="medium-11 cell section">
                          <p class="h3" v-if="ride.returnAddress === 'same'">
                            {{ ride.startAddress.number }} {{ ride.startAddress.street }}, {{ ride.startAddress.city }}, {{ ride.startAddress.postalCode }}
                          </p>
                          <p class="h3" v-else>
                            {{ ride.returnAddress.number }} {{ ride.returnAddress.street }}, {{ ride.startAddress.city }}, {{ ride.startAddress.postalCode }}
                          </p>
                        </div>

                      </div>

                    </div>

                    <!-- <div class="medium-8 cell">

                      <div class="grid-x grid-padding-x information">

                        <div class="cell">
                          <p class="h3" v-if="ride.pickupAddress === 'same'">
                            {{ ride.destinationAddress.number }} {{ ride.destinationAddress.street }}, {{ ride.destinationAddress.city }}<br />
                            {{ ride.pickupTime }}
                          </p>
                          <p class="h3" v-else>
                            {{ ride.pickupAddress.number }} {{ ride.pickupAddress.street }}, {{ ride.destinationAddress.city }}<br />
                            {{ ride.pickupTime }}
                          </p>
                          <p class="h3" v-if="ride.returnAddress === 'same'">
                            {{ ride.startAddress.number }} {{ ride.startAddress.street }}, {{ ride.startAddress.city }}
                          </p>
                          <p class="h3" v-else>
                            {{ ride.returnAddress.number }} {{ ride.returnAddress.street }}, {{ ride.startAddress.city }}
                          </p>
                        </div>

                      </div>

                    </div> -->

                  </div>

                  <hr v-if="ride.returnTrip === true" />

                  <div class="grid-x grid-padding-x information">

                    <div class="medium-4 cell">
                      <p class="h3">PAYMENT</p>
                    </div>

                    <div class="medium-8 cell">

                      <div class="grid-x grid-padding-x information">

                        <div class="cell">
                          <p v-if="info.type !== 'transHelp'" class="h3">
                            Charged Riders x {{ ride.companion }}<br />
                            <span v-if="ride.escort === true">Escort x 1<br /><br /></span>
                            <span v-if="ride.escort === false">Escort x 0<br /><br /></span>
                            Total Amount: ${{ ride.price }}
                          </p>
                          <p v-if="info.type === 'transHelp'" class="h3">
                            <em>Please contact Region of Peel to arrange ride payments.</em>
                          </p>
                        </div>

                      </div>

                    </div>

                  </div>

                  <hr />

                  <div v-if="ride.instructions" class="grid-x grid-padding-x special">

                    <div class="medium-4 cell">
                      <p class="h3">SPECIAL INSTRUCTIONS</p>
                    </div>

                    <div class="medium-8 cell">

                      <div class="grid-x grid-padding-x information">

                        <div class="cell">
                          <p class="h3">
                            {{ ride.instructions }}
                          </p>
                        </div>

                      </div>

                    </div>

                  </div>

                  <hr v-if="ride.instructions" />

                </div>

                <!-- <button type="button" class="primary btn button filled float-left" @click="$router.push('/trip-planner/customize')">Edit Ride</button> -->
                <router-link :to="{name: 'customize'}"  class="primary btn button filled float-left">Edit Ride</router-link>

                <hr />

                <button type="button" class="primary btn button hollow float-right" @click="initPayment()">Pay Now</button>
                <button type="button" class="primary btn button hollow float-right" @click="payfromAccount()">Pay from my account</button>

              </template>

              <template v-else>
                <!-- <p class="h3"><strong>Payment summary:</strong></p> -->
                <!-- <ul>
                  <li>{{ info.type }} Account: #{{ info.account }}</li>
                  <li><strong>Pay: {{ '$' + info.amount.toFixed(2) }} </strong></li>
                </ul> -->

                <div class="grid-x grid-padding-x personal">

                  <div class="medium-4 cell">
                    <p class="h3">ACCOUNT HOLDER</p>
                  </div>
                  <div class="medium-8 cell">
                    <p class="h3">
                      {{ info.first }} {{ info.last }}<br />
                      <span v-if="info.email">{{ info.email }}<br /></span>
                      <span v-if="info.phone">{{ info.phone }}<br /></span>
                      {{ info.type }}<span v-if="info.account">: {{ info.account }}</span>
                      <!-- Total Amount: {{ '$' + info.amount.toFixed(2) }} -->
                    </p>
                  </div>
                </div>

                <hr />

                <div class="grid-x grid-padding-x payment">

                  <div class="medium-4 cell">
                    <p class="h3">PAYMENT</p>
                  </div>
                  <div class="medium-8 cell">
                    <p class="h3">
                      <!-- {{ info.first }} {{ info.last }}<br /> -->
                      <!-- <span v-if="info.email">{{ info.email }}<br /></span> -->
                      <!-- <span v-if="info.phone">{{ info.phone }}<br /></span> -->
                      <!-- {{ info.type }}<span v-if="info.account">: {{ info.account }}</span> -->
                      Total Amount: {{ '$' + info.amount.toFixed(2) }}
                    </p>
                  </div>
                </div>

                <hr />

                <!-- <button type="button" class="primary btn button filled float-left" @click="$router.back(1)">Edit Payment</button> -->
                <router-link :to="{name: 'info'}"  class="primary btn button filled float-left">Edit Payment</router-link>

                <hr />

                <button type="button" class="primary btn button hollow float-right" @click="initPayment()">Pay Now</button>
                <!-- <button type="button" class="primary btn button hollow float-right" @click="payfromAccount()">Pay from my account</button> -->

              </template>

              <!-- <template v-if="info.type !== 'transHelp'">
                <button type="button" class="primary btn button hollow float-right" @click="initPayment()">Pay Now</button>
                <button type="button" class="primary btn button hollow float-right" @click="payfromAccount()">Pay from my account</button>
              </template> -->

              <template v-if="info.type === 'transHelp'">
                <button type="button" class="primary btn button hollow float-right" @click="payfromAccount()">Submit</button>
              </template>

            </div>

            <!-- <p>no ticket...</p> #} -->

        </div>
      </div>

      <modal @close="modalAction" :show="showModal">
        <!--
          you can use custom content here to overwrite
          default content
        -->
        <template v-slot:header>
          <h3>{{ modaldata.header }}</h3>
        </template>
        <template v-slot:body>
          <p>{{ modaldata.body }}</p>
        </template>
      </modal>
      <!-- <button @click="$emit('show')">modal</button> -->
    </div>
  </Layout>
</template>

<script>
// import this.axios from 'this.axios'
import { useInfo, useRide, useTxn } from '../store'
import Layout from '@/components/Layout.vue'
import Modal from '@/components/Modal.vue'

const responseCodeMap = {
  475: {
    body: 'The expiry date you entered is invalid. Please check your card and try again.'
  },
  476: {
    body: 'There was a problem processing your request. Please check your card and try again.'
  },
  477: {
    body: 'The card number you entered couldn\'t be found. Please check your card and try again.'
  },
  481: {
    body: 'That card cannot be processed, possibly because of insufficient funds. Please check your balance and try again.'
  },
  482: {
    body: 'That card has expired. To complete payment you need to use a valid card.'
  },
  486: {
    body: 'There was a problem with the CVV number you entered. Please check your card and try again.'
  },
  487: {
    body: 'There was a problem with the CVV number you entered. Please check your card and try again.'
  },
  489: {
    body: 'There was a problem with the CVV number you entered. Please check your card and try again.'
  },
  490: {
    body: 'There was a problem with the CVV number you entered. Please check your card and try again.'
  }
}

export default {
  components: { Layout, modal: Modal },
  created () {
    if (!this.info.account) this.$router.replace('/')
    else if (this.info.travel && !this.ride.destinationAddress.city) this.$router.replace('/trip-planner')
  },
  data () {
    return {
      infoState: useInfo(),
      rideState: useRide(),
      txnState: useTxn(),
      retry: 0,
      showError: false,
      errorMessage: '',
      showModal: false,
      modaldata: {
        header: '',
        body: '',
        footer: ''
      }
      // checkout: new window.monerisCheckout() // eslint-disable-line new-cap
    }
  },
  computed: {
    info () {
      return this.infoState.info
    },
    ride () {
      return this.rideState.ride
    }
  },
  methods: {
    initPayment () {
      // this.checkOut =
      this.axios.post('/booking/payments/preload', { info: this.info, ride: this.info.travel ? this.ride : null, retry: this.retry })
        .then(res => {
          if (res.data.error) {
            // server got an error from Faunadb?
            this.showError = true
            this.errorMessage = res.data.error
            return
          }
          if (res.data.preload.response.success) {
            const checkout = new window.monerisCheckout() // eslint-disable-line new-cap
            checkout.setMode(`${process.env.NODE_ENV === 'production' ? 'prod' : 'qa'}`)
            checkout.setCheckoutDiv('monerisCheckout')
            checkout.setCallback('page_loaded', callbackParams => {
              // console.log(' ::::: checkout page loaded', callbackParams)
            })
            checkout.setCallback('cancel_transaction', callbackParams => {
              console.log(' ::::: checkout cancel', callbackParams)
            })
            checkout.setCallback('error_event', err => {
              console.log(' ::::: checkout error', err)
            })
            checkout.setCallback('payment_complete', callbackParams => {
              this.axios.post('/booking/payments/complete', { ticket: res.data.preload.response.ticket, info: this.info, trip: this.info.travel ? res.data.ride : null, retry: this.retry })
                .then(receipt => {
                  checkout.closeCheckout()
                  if (receipt.data.success) {
                    this.txnState.setReceipt(receipt.data.data)
                    this.$router.push('/receipt')
                  } else {
                    this.modaldata.header = 'Payment was declined.'
                    this.modaldata.body = responseCodeMap[receipt.data.data.response_code]
                      ? responseCodeMap[receipt.data.data.response_code].body
                      : 'We\'re not sure what the problem is... Click "OK" if you would you like to try to pay again. Click "Cancel" to start over.'
                    this.showModal = true
                  }
                })
            })
            checkout.startCheckout(res.data.preload.response.ticket)
          }
        })
    },
    payfromAccount () {
      this.rideState.setRide({ debitAccount: true })
      this.$router.push('/receipt')
    },
    modalAction (val) {
      if (!val) {
        // this.infoState.clear()
        // this.rideState.clear()
        // this.txnState.clear()
        this.$router.push('/trip-planner')
        return
      }
      if (this.modaldata.header === 'Payment was declined.') {
        this.retry++
        this.initPayment()
        this.showModal = false
      }
    }
  }
}
</script>

<style>
  .checkoutHtmlStyleFromiFrame .footer {
    display: none;
  }
</style>
