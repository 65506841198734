<template>
<!-- <div v-if="showModal"> -->
  <transition name="modal">
    <div class="modal-mask" v-if="showModal">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header" />
          </div>

          <div class="modal-body">
            <slot name="body" />
          </div>

          <div class="modal-footer">
            <slot name="footer" />
            <div class="buttons">
              <slot name="buttons">
                <button class="modal-cancel-button" @click="$emit('close', false)">
                  Cancel
                </button>
                <button class="modal-default-button" @click="$emit('close', true)">
                  OK
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
<!-- </div> -->
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    }
  }
}
</script>

<style scoped>
  #app {
    padding: 1rem;
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-footer .buttons {
    padding: 0 .5rem;
    text-align: right;
  }

  .modal-default-button {
    display: inline-block;
    padding-left: 1rem;
    margin-top: 1rem;
  }
  .modal-cancel-button {
    display: inline-block;
    padding-left: 1rem;
    margin-top: 1rem;
  }

  /*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity 0.5s ease;
  }

  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
  }

</style>
